// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  recaptcha: {
    siteKey: '6LeXiicUAAAAAAUWDcgiSy8Q-4xeqfkiVj_42D4S',
  },
  apiCountriesUrl: 'https://prod-192.westeurope.logic.azure.com:443/workflows/35a9dadc1e2040e48ee8f79005b295a5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=M0BYnRzleIoqJsYk3l_QHQcL0TEIKyrve2pteTcsif4',
  apiCountiesUrl: 'https://prod-231.westeurope.logic.azure.com:443/workflows/dc1df297b3ec4c2a808e88dbf7acee09/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=AZs7_gk-UBniuQdLFKcrpmzX-0UmQnP2ka4HLE0JZ-k',
  apiCurrenciesUrl: 'https://prod-45.westeurope.logic.azure.com:443/workflows/1a1952fd5c724138856ff308a507e5e2/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=upyQOgM_1POLJpDLU3uYlI6SdEwz4smp1EB4H6_XkcE',
  apiFindOrganizationUrl: 'https://prod-197.westeurope.logic.azure.com:443/workflows/1984da381dae44968c18fbb34179902f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=rde5G7ujZdG-kiHAZgGKZyF6lxErrkJr_UAS55swj3w',
  apiCreateRequestUrl: 'https://prod-66.westeurope.logic.azure.com:443/workflows/a5f4c62adc474dff9a6c5c562cfed100/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Xtiuc5pONu1mLBUYZy-AISV7oLcupdUO8d-s-FrzqHg',
  apiRequestsUrl: 'https://prod-108.westeurope.logic.azure.com:443/workflows/2b5d6c536f9e498ea1741711dd1f5d18/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Fd5b8GNlrx0w8X5XNgaqaQhFBNqF988QlCHOBD52KmA',
  apiRequestDocumentsUrl: 'https://prod-233.westeurope.logic.azure.com:443/workflows/f19b32f41015415a98199492d4ad3217/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=jIuqjZYaGjsH2tfOSKQgviDmeswl0sMeHT6o35JhFRI',
  apiRequestDocumentsListUrl:'https://prod-92.westeurope.logic.azure.com:443/workflows/80d36982accb489bbc440c7f1e74fce7/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=zdmCh6yZyKI_EuwnLOBRcyzHEnJRX6VOYxSyi9WiAUw',
  apiRequestNumberUrl: 'https://prod-58.westeurope.logic.azure.com:443/workflows/7f3a1aa540d740bbb2e8081806c98432/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=9Zpobj3wA_ViggD3PAZQpg6xrMSG-JBCFx4nBzgBkEU',
  apiPortalLoginUrl: 'https://prod-100.westeurope.logic.azure.com:443/workflows/b1327b16ac2b488ba033746fd4296c41/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=zlNJpTOGh1PzklGbb74ioMY-9vBzsBcq8RgHR1TsxNs',
  apiApplicantRegistrationUrl: 'https://prod-122.westeurope.logic.azure.com:443/workflows/876039d7179c42059228d5ddae4c12fa/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2aMhFQOw_qy1_RmoHkOm8SvnN6bEblzflWqvOKBa5wk',
  apiApplicantMobileEmailVerification: 'https://prod-42.westeurope.logic.azure.com:443/workflows/ba68ecf3d5124029b0d0eb151a7c590f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=lnrOhdLHUMMvHnRomyqTvz-6sAe0BWyIOuDe-gwaT3I',
  apiDeleteApplicant: 'https://prod-133.westeurope.logic.azure.com:443/workflows/555e52b426984f18bf15e533ceced9a7/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DrAHxTcZUJb_E5DJ3pIxittK4xFFivOyMBF7KF7eyCI',
  apiRetainOldApplicant: 'https://prod-42.westeurope.logic.azure.com:443/workflows/a30240c6586940f7b47a6cdba4f4ebc5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=CgcHWcYzRH2jgWKcvvSQvqLNsdBPgpR38AN_wXalfA8',
  apiInitialChangePassword: 'https://prod-75.westeurope.logic.azure.com:443/workflows/d72ed3b6350a42859084f062520e6238/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=8bKD6gF-t5SC28K7xPbfJ7WZdD3oNUMTQyKrzWOiM_U',
  apiChangePassword: 'https://prod-191.westeurope.logic.azure.com:443/workflows/94605e4d7641410e860f68c50d005b60/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Ca2pY87XTD4BubJ0i4sJ--MPghVLIkM4Lgu7nTaQXPE',
  apiCheckEmailOrMobilePhoneExistence: 'https://prod-20.westeurope.logic.azure.com:443/workflows/9570c970ba994bbaab68adff7ae2786d/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=JO8b4123jWcGdrpXn9Nxv2Qx5pCqMbtQR0gfJVzQl7w',
  apiRequestLog: 'https://prod-07.westeurope.logic.azure.com:443/workflows/21b3ee16f988483697e191ff304e9ed8/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=S1Ty6JzdyUfL-zh8AuwqwDVUrgcSA0eqxGmHeNYCVTo',
  apiErrorLogger: 'https://prod-185.westeurope.logic.azure.com:443/workflows/64f288fb0d97423eb472d77735c2c6d6/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Qbolu6jF-o00HQa1rO-ChJuybl3ZU_cGVKsPgPj1Pxg'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error',  // Included with Angular CLI.
