import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SNF Grant Portal';
  public constructor(private titleService: Title) { }

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    localStorage.setItem('logout', 'OK');
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

}
